import { Button, Carousel, Col, Grid, Row } from "rsuite";

import { useLowerBreakpoint } from "lib/breakpoint";

import "./landingBanner.scss";

function LandingBanner() {
  return (
    <Carousel className="carousel-banner" id="home">
      <div className="landing-banner landing-banner__slide-main">
        <Grid className="banner-grid">
          <Row>
            {useLowerBreakpoint("md") && <div className="banner-image"></div>}
            <div className="banner-content">
              <h1 className="banner-title font-bossaBold">PRAKTISI</h1>
              <p className="banner-tagline">
                Membawa Ilmu Industri ke Ruang Kelas
              </p>
              <Button
                appearance="primary"
                target="_blank"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/praktisi/registrasi`}
                className={`landing-button bg-primary mr-2${
                  useLowerBreakpoint("md") ? " mb-2" : ""
                }`}
                block={useLowerBreakpoint("md")}
              >
                DAFTAR SEBAGAI PRAKTISI
              </Button>
              <Button
                appearance="primary"
                target="_blank"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/registrasi`}
                className="landing-button bg-primary"
                block={useLowerBreakpoint("md")}
              >
                DAFTAR SEBAGAI DOSEN/KOOR PT
              </Button>
            </div>
          </Row>
        </Grid>
      </div>
      <div className="landing-banner landing-banner__slide-dosen">
        <Grid className="banner-grid">
          <Row>
            {useLowerBreakpoint("md") && <div className="banner-image"></div>}
            <div className="banner-content">
              <h1 className="banner-title font-bossaBold">PERGURUAN TINGGI</h1>
              <p className="banner-tagline">
                Berkolaborasi Bersama Praktisi Ahli ke Kelas Anda
              </p>
              {/* <Button
                appearance="primary"
                target="_blank"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/praktisi/registrasi`}
                className={`landing-button bg-primary mr-2${
                  useLowerBreakpoint("md") ? " mb-2" : ""
                }`}
                block={useLowerBreakpoint("md")}
              >
                DAFTAR SEBAGAI PRAKTISI
              </Button> */}
              <Button
                appearance="primary"
                target="_blank"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/registrasi`}
                className="landing-button bg-primary"
                block={useLowerBreakpoint("md")}
              >
                DAFTAR SEBAGAI DOSEN
              </Button>
            </div>
          </Row>
        </Grid>
      </div>
      {/* <div className="landing-banner landing-banner__slide-praktisi">
        <Grid className="banner-grid">
          <Row>
            {useLowerBreakpoint("md") && <div className="banner-image"></div>}
            <div className="banner-content">
              <h1 className="banner-title font-bossaBold">
                PRAKTISI AHLI
                <br />
                BERKOLABORASI
                <br />
                MENDIDIK ANAK NEGERI
              </h1>
              <p className="banner-tagline">
                Sumbangkan ilmu dan pengalaman Anda untuk mempersiapkan SDM
                Unggul Indonesia bersama para dosen juara.
              </p>
              <Button
                appearance="primary"
                target="_blank"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/praktisi/registrasi`}
                className={`landing-button bg-primary mr-2${
                  useLowerBreakpoint("md") ? " mb-2" : ""
                }`}
                block={useLowerBreakpoint("md")}
              >
                DAFTAR SEBAGAI PRAKTISI
              </Button>
              <Button
                appearance="primary"
                href="#kolaborasi"
                className="btn-blue landing-button"
                block={useLowerBreakpoint("md")}
              >
                KETAHUI LEBIH LANJUT
              </Button>
            </div>
          </Row>
        </Grid>
      </div>
      <div className="landing-banner landing-banner__slide-dosen">
        <Grid className="banner-grid">
          <Row>
            {useLowerBreakpoint("md") && <div className="banner-image"></div>}
            <div className="banner-content">
              <h1 className="banner-title font-bossaBold">
                DOSEN JUARA
                <br />
                MEMBUKA CAKRAWALA DUNIA KEPADA MAHASISWA
              </h1>
              <p className="banner-tagline">
                Datangkan praktisi ahli ke kelas Anda! Gratis.
              </p>
              <Button
                appearance="primary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSck02fRD52la8b6H62_w3DNKzfgYBhjrC3uMrkM31wunMVWiw/viewform?usp=sf_link"
                target="_blank"
                className={`landing-button bg-primary mr-2${
                  useLowerBreakpoint("md") ? " mb-2" : ""
                }`}
                block={useLowerBreakpoint("md")}
              >
                SAYA SIAP BERKOLABORASI
              </Button>
              <Button
                appearance="primary"
                href="#kolaborasi"
                className="btn-blue landing-button"
                block={useLowerBreakpoint("md")}
              >
                KETAHUI LEBIH LANJUT
              </Button>
            </div>
          </Row>
        </Grid>
      </div>
      <div className="landing-banner landing-banner__slide-mahasiswa">
        <Grid className="banner-grid">
          <Row>
            {useLowerBreakpoint("md") && <div className="banner-image"></div>}
            <div className="banner-content">
              <h1 className="banner-title font-bossaBold">
                MAHASISWA/I VISIONER <br />
                SIAP MENYONGSONG <br />
                MASA DEPAN GEMILANG
              </h1>
              <p className="banner-tagline">
                Daftar pada kelas yang membuka wawasan kamu mengenai dunia
                kerja.
              </p>
              <Button
                appearance="primary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSck02fRD52la8b6H62_w3DNKzfgYBhjrC3uMrkM31wunMVWiw/viewform?usp=sf_link"
                target="_blank"
                className={`landing-button bg-primary mr-2${
                  useLowerBreakpoint("md") ? " mb-2" : ""
                }`}
                block={useLowerBreakpoint("md")}
              >
                SAYA SIAP BERKOLABORASI
              </Button>
              <Button
                appearance="primary"
                href="#kolaborasi"
                className="btn-blue landing-button"
                block={useLowerBreakpoint("md")}
              >
                KETAHUI LEBIH LANJUT
              </Button>
            </div>
          </Row>
        </Grid>
      </div> */}
    </Carousel>
  );
}

export default LandingBanner;
