import { Container, Grid } from "rsuite";
import LandingBanner from "./sections/LandingBanner";
import LandingTentang from "./sections/LandingTentang";
import LandingModel from "./sections/LandingModel";
import LandingSyarat from "./sections/LandingSyarat";
import LandingTimeline from "./sections/LandingTimeline";
import LandingKolaborasi from "./sections/kolaborasi/LandingKolaborasi";
import LandingSubscribe from "./sections/LandingSubscribe";
import "./landing.scss";
import "./base.scss";

function Landing() {
  return (
    <Container style={{padding: 0}} className="landing">
        <div style={{ backgroundColor: '#FFF' }}>
          <Grid className="landing-grid" style={{padding: '32px 0', color: '#1C1C84', backgroundColor: '#FFF'}}>
            <p>
              Yth. Bapak/Ibu,
            </p>
            <p>
              Bersama ini kami informasikan bahwa platform Program Praktisi Mengajar akan menjalani proses pemeliharaan dan peningkatan layanan sistem pada <b>Rabu, 13 Desember 2023 pukul 09.00 WIB s.d. Kamis, 14 Desember 2023 pukul 13.00 WIB.</b> 
            </p>
            <p>
              Selama proses tersebut, platform tidak dapat diakses dan digunakan. Platform dapat kembali digunakan setelah proses pemeliharaan selesai.
            </p>
            <p>
              Selama proses pemeliharaan, Pusat Bantuan dapat diakses melalui tautan berikut: <a style={{ textDecoration: 'underline' }} target="_blank" href="https://pusatinformasi.praktisimengajar.id/hc/en-gb/requests/new">https://pusatinformasi.praktisimengajar.id/hc/en-gb/requests/new</a>
            </p>
            <p>
              Demikian kami sampaikan. Atas perhatian dan kerja sama yang baik, kami ucapkan terima kasih.
            </p>
            <br/>
            <p>
              Salam Kolaborasi,<br/>Tim Pelaksana Program Praktisi Mengajar
            </p>
          </Grid>
        </div>
        <LandingBanner />
        <LandingTentang />
        {/* <LandingModel /> */}
        <LandingKolaborasi />
        {/* <LandingTimeline /> */}
        <LandingSyarat />
        {/* <LandingSubscribe /> */}
    </Container>
  );
}

export default Landing;
