import {
  Navbar,
  Nav,
  Grid,
  IconButton,
  Drawer,
  Sidenav,
  Dropdown,
} from "rsuite";
import "./landingNavbar.scss";

import logoTutWuriHandayani from "assets/tut-wuri-handayani.png";
import logoKM from "assets/navbar/km.png";
import logoPM from "assets/navbar/pm.png";
import logoPMVokasi from "assets/navbar/pm-vokasi.png";
import logoLPDP from "assets/navbar/lpdp.png";
import MenuIcon from "@rsuite/icons/Menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLowerBreakpoint, useHigherBreakpoint } from "lib/breakpoint";
function LandingNavBar(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Navbar
      appearance="subtle"
      className={`landing-navbar ${props.className ?? ""}`}
    >
      <Grid className="landing-grid" style={{ padding: "0" }}>
        <div className="d-flex justify-content-between">
          <Navbar.Brand>
            <a href={`https://kemdikbud.go.id/`} style={{ flexBasis: "160px" }}>
              <img
                className="tut-wuri-handayani-logo"
                src={logoTutWuriHandayani}
                alt="mbkm-logo"
              ></img>
            </a>
            <a href={`https://lpdp.kemenkeu.go.id/`}>
              <img className="lpdp-logo" src={logoLPDP} alt="mbkm-logo"></img>
            </a>

            <a href={"/"}>
              <img className="pm-logo" src={logoPM} alt="mbkm-logo"></img>
            </a>
            <a href={"/"}>
              <img className="pm-logo" style={{ marginLeft: 8 }} src={logoPMVokasi} alt="mbkm-logo"></img>
            </a>
            <a href={"https://kampusmerdeka.kemdikbud.go.id/"} style={{ marginLeft: 8 }}>
              <img className="mbkm-logo" src={logoKM} alt="mbkm-logo"></img>
            </a>
          </Navbar.Brand>
          {useHigherBreakpoint("md") && (
            <Nav className="pm-navbar d-flex align-items-center text-white">
              <Nav.Item href="/#home">Beranda</Nav.Item>
              <Nav.Item href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/login`}>
                Masuk/Login
              </Nav.Item>
              {/* <Nav.Item href="/#kolaborasi">Ayo Kolaborasi</Nav.Item> */}
              {/* <Nav.Item>Punya Pertanyaan?</Nav.Item> */}
              <Nav.Dropdown
                title="Panduan"
                style={{ textTransform: "capitalize !important" }}
              >
                <a target="_blank" href="/bukupanduan" className="link-nav">
                  <Nav.Dropdown.Item>Buku Panduan</Nav.Dropdown.Item>
                </a>
                {/* <a
                  href="https://pusatinformasi.praktisimengajar.id"
                  className="link-nav"
                >
                  <Nav.Dropdown.Item>
                    Pusat Informasi
                  </Nav.Dropdown.Item>
                </a> */}
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_CONTACT_US_URL}`}
                  className="link-nav" rel="noreferrer"
                >
                  <Nav.Dropdown.Item>Hubungi Kami</Nav.Dropdown.Item>
                </a>
              </Nav.Dropdown>
              <Nav.Item href={'https://pusatinformasi.praktisimengajar.id/hc/en-gb'} target='_blank'>
                Butuh Bantuan ?
              </Nav.Item>
            </Nav>
          )}
          {useLowerBreakpoint("md") && (
            <div className="d-flex align-items-center pr-3">
              <IconButton
                icon={<MenuIcon style={{ fontSize: "16px" }} />}
                appearance="subtle"
                className="text-white"
                onClick={() => setOpen(true)}
              />
            </div>
          )}
          <Drawer placement="top" open={open} onClose={() => setOpen(false)}>
            <Drawer.Body>
              <Sidenav
                className="landing-sidenav"
                defaultOpenKeys={[]}
                activeKey="1"
              >
                <Sidenav.Body className="d-flex justify-content-center">
                  <Nav>
                    <Nav.Item href="#home" eventKey="1">
                      Beranda
                    </Nav.Item>
                    {/* <Nav.Item href="#kolaborasi" eventKey="2">
                      Ayo Berkolaborasi
                    </Nav.Item> */}
                    <Nav.Item
                      href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/login`}
                    >
                      Masuk/Login
                    </Nav.Item>
                    <Dropdown eventKey="3" title="Panduan">
                      <Dropdown.Item
                        as="a"
                        target="_blank"
                        href="/bukupanduan"
                        eventKey="3-1"
                      >
                        Buku Panduan
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        as="a"
                        href="https://pusatinformasi.praktisimengajar.id"
                        eventKey="3-2"
                      >
                        Pusat Informasi
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        as="a"
                        target="_blank"
                        href={`${process.env.REACT_APP_CONTACT_US_URL}`}
                        eventKey="3-3"
                      >
                        Hubungi Kami
                      </Dropdown.Item>
                    </Dropdown>
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
            </Drawer.Body>
          </Drawer>
        </div>
      </Grid>
    </Navbar>
  );
}

export default LandingNavBar;
